
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { HierarchyRectangularNode } from 'd3'
import {
  AccountingMethod,
  AggregateFlow,
  AggregateFlowsRequestFilters,
  AttributedFlow,
  HierarchicalAggregateFlows,
  SingleFlowDirection,
  SortMap,
  TripletCluster,
  TxnTriplet
} from '@/utils/api'
import { titleCase, prettyRoundedNumber, timeToMilliseconds } from '@/utils/general'
import { WebGLContext, WebGLGraph } from '@/utils/graph'
import { TreeMap } from '@/utils/treemap'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { Target, TransactionFlowTarget } from '@/store/investigations/viz'
import { Header } from './types/genericTable'
import { categoryColor } from '@/utils/colors'
import { convertHopsToReadable, nodeTypeToIdString } from '@/utils/viz'
import { MinimalFlow } from '@/store/investigations/flows'
import { classifyInput } from '@/utils/validate'
import SortBar, { SortResponse } from './SortBar.vue'

interface Breadcrumb {
  label: string
  depth: number
  symbol?: string
}

interface TooltipInfoBranch {
  name: string
  numLeaves: number
  amountSum: number | string
  symbol?: string
}

interface TooltipInfoLeaf {
  name: string
  amount: number
  symbol?: string
  numPaths: number
  shortestPath: number
  longestPath: number
}

interface TooltipInfo {
  name: string
  type: 'branch' | 'leaf' | 'attribution'
  data: TooltipInfoBranch | TooltipInfoLeaf
}

type AddressEntity = { address: string }
type AttributionEntity = { attribution: string }
type ClusterEntity = { cluster: string }

type HierarchyEntity = AttributionEntity | AddressEntity | ClusterEntity
type HierarchyEntityFilter =
  | { sourceAttribution: string }
  | { destinationAttribution: string }
  | { sourceAddress: string }
  | { destinationAddress: string }
  | { sourceCluster: string }
  | { destinationCluster: string }

interface CategoryDetailBase {
  amount: number
  longestPath: number
  // name: string
  numPaths: number
  shortestPath: number
}

// interface CategoryDetails extends CategoryDetailBase {
//   destinationAddress?: string
//   destinationAttribution?: string
//   destinationCluster?: string
//   destinationCategory?: string
//   sourceAddress?: string
//   sourceAttribution?: string
//   sourceCluster?: string
//   sourceCategory?: string
// }

interface Category extends CategoryDetailBase {
  // name: string
  // children: AggregateFlow[]
  symbol: string
  sourceCluster?: string
  destinationCluster?: string
}

function isAddressEntity(entity: HierarchyEntity | undefined): entity is AddressEntity {
  if (entity == null) {
    return false
  }
  return (entity as AddressEntity).address != null
}

function isClusterEntity(entity: HierarchyEntity | undefined): entity is ClusterEntity {
  if (entity == null) {
    return false
  }
  return (entity as ClusterEntity).cluster != null
}

@Component({
  components: {
    SortBar,
    ServerTable
  },
  computed: {
    ...mapState([
      'target',
      'targetNetwork',
      'categoryScores',
      'aggregateFlows',
      'attributedFlows',
      'hierarchy',
      'attributionFlows',
      'attributionFlowsKey',
      'attributionFlowsFail'
    ])
  }
})
export default class TreeMapGraph extends Vue {
  @Prop() enableOptions!: boolean
  @Prop() visible!: boolean // passed in from parent, true if summary tab is visible
  public loading: boolean = false
  // start - mapped from vuex state
  public target!: Target
  public targetNetwork!: string
  public entity: HierarchyEntity | undefined
  public aggregateFlows!: AggregateFlow[]
  public attributedFlows!: AttributedFlow[]
  public hierarchy!: HierarchicalAggregateFlows
  public attributionFlows!: Map<string, HierarchicalAggregateFlows>
  public attributionFlowsKey!: string
  public attributionFlowsFail!: number
  public categoryScores!: { [category: string]: number }
  // end - mapped from vuex state
  public entityFilter: HierarchyEntityFilter | undefined
  // pulled from attributionFlows map after data is loaded
  // private attributedFlows: AttributedFlows = { name: 'initial', children: [] }
  // if treemap isn't visible, don't render until it is
  private shouldUpdate: boolean = false

  private webGLGraph!: WebGLGraph
  private webGLContext!: WebGLContext
  private treemap!: TreeMap

  public depth: number = 0
  public breadcrumbs: Array<Breadcrumb> = []
  public row?: AggregateFlow = undefined
  public showOptions: boolean = false

  // layouts
  public layouts = ['resquarify', 'binary', 'ratio']
  public layoutIndex = 0
  public layout = 'resquarify'
  public sqRatio: number = 1.5

  // scale
  public scales = ['linear', 'log']
  public scaleIndex = 1
  public scale = 'log'
  public logMin: number = 10
  public logMax: number = 1000

  // direction (0 is incoming, 1 is outgoing)
  public directionIndex: number = 0

  // accounting (0 is balance, 1 is LIFO)
  public accountingIndex: number = 1

  // paging
  public itemsPerPage: number[] = [50, 100, 200, 400]
  public perPage: number = 50
  public page: number = 1
  public sortFields = ['amount', 'time']
  public sorts: SortMap = { amount: 'desc' }

  //tooptip
  public tooltipActive = false
  public tooltipX: number = 0
  public tooltipY: number = 0
  public tooltipInfo: TooltipInfo = {
    name: '',
    type: 'branch',
    data: {
      name: '',
      numLeaves: 0,
      amountSum: 0
    }
  }

  public show: boolean = false

  // 3rd layer attribution flows
  private attributionFlowSourceData?: { depth: number; leaf: HierarchyRectangularNode<any>; attribution: string }

  // format - treemap / table
  public formatIndex = 1

  public tableDatas: Array<any> = [[], [], []]

  public failed: boolean = false

  get categoryHeaders(): Array<Header> {
    return [
      {
        text: 'Category',
        value: `${this.direction}Category`,
        itemClass: this.categoryColor,
        sortable: false
      },
      {
        text: 'Currencies',
        value: 'symbol',
        transform: (symbol: string[]) => symbol.join(', '),
        sortable: false
      }
    ]
  }
  get categoryDetailHeaders(): Array<Header> { 
    return [
      {
        text: 'Attribution',
        value: `${this.direction}Attribution`,
        sortable: false
      },
      {
        text: 'Total',
        value: 'amount',
        transformValue: '',
        transform: (item: AggregateFlow) => `${prettyRoundedNumber(item.amount, 4)} ${item.symbol}`,
        sortable: false
      },
      {
        text: 'Shortest Path',
        value: 'shortestPath',
        transform: this.pathToHops,
        sortable: false
      }
    ]
  }
  get attributionFlowHeaders(): Array<Header> {
    return [
      {
        text: '',
        value: '',
        select: {
          disabled: true,
          selected: this.flowGraphed
        },
        width: '5px',
        sortable: false
      },
      {
        text: 'Hops',
        value: 'shortestPath',
        transform: this.pathToHops,
        sortable: false
      },
      {
        text: 'Amount',
        value: 'amount',
        transformValue: '',
        transform: (item: AggregateFlow) => `${prettyRoundedNumber(item.amount, 4)} ${item.symbol}`,
        sortable: false
      },
      {
        text: 'Date/Time',
        value: 'source',
        transformValue: '',
        transform: (item: AggregateFlow) => this.formatDateRange(item.source.time, item.destination.time),
        // can't sort like this when using server-side paging
        // sort: (a: TxnTripletTime, b: TxnTripletTime) => a.time - b.time,
        sortable: false
      }
    ]
  }

  get tableHeaders(): Array<Header> {
    if (this.depth === 0) {
      return this.categoryHeaders
    } else if (this.depth === 1) {
      return this.categoryDetailHeaders
    }
    return this.attributionFlowHeaders
  }

  get network(): string {
    return this.target.network
  }

  public categoryColor(category: string): string {
    return categoryColor(category, this.categoryScores, true, true) as string
  }

  public tableData(): Array<any> {
    if (this.depth === 0) {
      return this.aggregateFlows
      // return this.tableDatas[0]
    } else if (this.depth === 1) {
      return this.aggregateFlows
      // return this.tableDatas[1]
    }
    return this.attributedFlows
    // return this.tableDatas[2]
  }

  mounted() {
    this.targetUpdated()
  }

  public titleCase(str: string): string {
    if (str == null || str === '') {
      return ''
    }
    return titleCase(str)
  }

  // public layoutChanged() {
  //   this.layout = this.layouts[this.layoutIndex]
  //   this.destroy()
  //   this.graphInit()
  // }

  // public logScaleChanged() {
  //   this.scale = this.scales[this.scaleIndex]
  //   this.destroy()
  //   this.graphInit()
  // }

  // public async formatChanged() {
  //   this.depth = 0
  //   this.breadcrumbs = []
  //   if (this.formatIndex === 0) {
  //     // treemap
  //     await this.$nextTick()
  //     this.graphInit()
  //   } else if (this.formatIndex === 1) {
  //     // table
  //     this.destroy()
  //   }
  // }

  public async tableRowClick(row: Category | AggregateFlow | AttributedFlow) {
    this.noHover()
    // if we're already on depth 2, do this and return
    if (this.depth === 2) {
      const { source, destination } = row as AttributedFlow
      await this.graphFlow(source, destination)
      return
    }
    if (this.depth <= 1) {
      const name = this.depth === 0 ? (row as AggregateFlow)[`${this.direction}Category`]
        : (row as AggregateFlow)[`${this.direction}Attribution`]
      this.breadcrumbs.push({ depth: this.depth, label: name ?? '?' })
      this.row = row as AggregateFlow
      this.setDepth(this.depth + 1)
    }
  }

  public tableRowHover(row: Category | AggregateFlow | AttributedFlow) {
    if (this.depth === 2) {
      const { source, destination, shortestPath } = row as AttributedFlow
      const sourceFlow: MinimalFlow = { targetTransaction: source as TripletCluster, sending: true, minHops: shortestPath }
      const destinationFlow: MinimalFlow = { targetTransaction: destination as TripletCluster, sending: false, minHops: shortestPath }
      this.$store.dispatch('highlightFlow', { sourceFlow, destinationFlow })
    } else if (this.depth === 1) {
      const id = (row as AggregateFlow)[`${this.direction}Attribution`] as string
      this.$store.dispatch('selectNode', nodeTypeToIdString({ id, type: 'attribution', network: this.network }))
    }
  }

  public noHover() {
    if (this.depth === 2) {
      this.$store.dispatch('highlightFlow', {})
    } else if (this.depth === 1) {
      this.$store.dispatch('selectNode', '')
    }
  }

  get direction(): SingleFlowDirection {
    return this.directionIndex === 0 ? 'source' : 'destination'
  }

  get accounting(): AccountingMethod {
    return this.accountingIndex === 0 ? 'balance' : 'LIFO'
  }

  get numLeaves(): number {
    if (this.tooltipInfo.type === 'branch') {
      return (<TooltipInfoBranch>this.tooltipInfo.data).numLeaves
    }
    return 0
  }

  get amount(): string {
    let amount: string | number = 0
    if (this.tooltipInfo.type === 'branch') {
      amount = (<TooltipInfoBranch>this.tooltipInfo.data).amountSum
    } else {
      amount = (<TooltipInfoLeaf>this.tooltipInfo.data).amount
    }
    if (typeof amount === 'number') {
      return prettyRoundedNumber(amount, 2, true)
    } else if (typeof amount === 'string') {
      return amount
    }
    return ''
  }

  get symbol(): string | undefined {
    return this.tooltipInfo.data.symbol
  }

  get numPaths(): number | undefined {
    if (this.tooltipInfo.type === 'leaf') {
      const leaf = <TooltipInfoLeaf>this.tooltipInfo.data
      return this.pathToHops(leaf.numPaths)
    }
    return undefined
  }

  get shortestPath(): number | undefined {
    if (this.tooltipInfo.type === 'leaf') {
      const leaf = <TooltipInfoLeaf>this.tooltipInfo.data
      return this.pathToHops(leaf.shortestPath)
    }
    return undefined
  }

  get longestPath(): number | undefined {
    if (this.tooltipInfo.type === 'leaf') {
      const leaf = <TooltipInfoLeaf>this.tooltipInfo.data
      return this.pathToHops(leaf.longestPath)
    }
    return undefined
  }

  private pathToHops(count: number): number {
    // this won't be perfect without knowing whether the focus is an input or output; assume it'll match direction
    return convertHopsToReadable(count, this.direction === 'source', this.direction === 'source')
  }

  public onSort(selected: SortResponse) {
    this.sorts = {
      [selected.sortBy[0]]: selected.sortDesc[0] === 1 ? 'desc' : 'asc' // currently only one sort allowed
    }
    this.page = 1
    this.getAttributionFlows()
  }

  // 3rd layer
  private async getAttributionFlows() {
    // this.attributedFlows = { name: 'initial', children: [] } // unset so we don't briefly show incorrect data
    const { entity, entityFilter, row: data } = this
    if (data != null) {
      let filters
      if (this.direction === 'source' && data.sourceAttribution != null) {
        if (classifyInput(data.sourceAttribution).networkTypes.length > 0) { // must be a cluster/address
          filters = { sourceCluster: data.sourceAttribution, ...entityFilter }
        } else {
          filters = { sourceAttribution: data.sourceAttribution, ...entityFilter }
        }
      } else if (this.direction === 'destination' && data.destinationAttribution != null) {
        if (classifyInput(data.destinationAttribution).networkTypes.length > 0) { // must be a cluster/address
          filters = { destinationCluster: data.destinationAttribution, ...entityFilter }
        } else {
          filters = { destinationAttribution: data.destinationAttribution, ...entityFilter }
        }
      }
      // const attributionFlowReq = {
      //   network: this.network,
      //   side: this.direction,
      //   ...entity,
      //   filters,
      //   trace: this.accounting,
      //   symbol: data.symbol
      // }
      const attributedFlowsReq = {
        network: this.network,
        filters: { ...filters, symbol: data.symbol },
        trace: this.accounting,
        page: this.page,
        perPage: this.perPage,
        sorts: this.sorts
      }
      this.loading = true
      // await this.$store.dispatch('getAttributionFlows', attributionFlowReq)
      // this.attributionFlowsUpdated()
      const results = await this.$store.dispatch('getAttributedFlows', attributedFlowsReq)
      if (!results) this.page--
      this.loading = false
    }
  }

  // graph 3rd layer flow
  private async graphFlow(source: TxnTriplet, destination: TxnTriplet) {
    this.loading = true
    await this.$store.dispatch('setAndGraphSubnetorkFlow', {
      source,
      destination,
      network: this.network,
      trace: this.accounting
    })
    this.loading = false
  }

  public flowGraphed(row: AttributedFlow) {
    const { source, destination } = row as AttributedFlow
    const { id: sourceId, isOutput: sourceIsOutput, index: sourceIndex } = source
    const { id: destinationId, isOutput: destinationIsOutput, index: destinationIndex } = destination
    const sourceKey = `${sourceId}|${sourceIsOutput}|${sourceIndex}|${true}`
    const destinationKey = `${destinationId}|${destinationIsOutput}|${destinationIndex}|${false}`
    const existingKeys = new Set(
      (<TransactionFlowTarget[]>this.$store.state.transactionFlowTargets).map(
        ({ targetTransaction, sending }) => {
          const { id, isOutput, index } = targetTransaction
          return `${id}|${isOutput}|${index}|${sending}`
        }
      )
    )
    return existingKeys.has(sourceKey) && existingKeys.has(destinationKey)
  }

  // @Watch('tableDatas', { deep: true })
  // private graphInit() {
  //   this.shouldUpdate = false
  //   this.breadcrumbs = []
  //   this.webGLGraph = new WebGLGraph({ layers: 3 })
  //   const container = this.$refs.treemap as HTMLElement
  //   const webGLContext = this.webGLGraph.createCanvas(container)
  //   this.webGLContext = webGLContext
  //   const { categoryScores } = this
  //   const treemapParams = {
  //     ...webGLContext,
  //     categoryScores,
  //     updateDepth: this.updateDepth.bind(this)
  //   }
  //   this.treemap = new TreeMap(treemapParams)

  //   this.createGraph(container, webGLContext)

  //   this.treemap.events.on('leaf:mouseover', (event: HierarchyRectangularNode<MapChild>) => {
  //     this.tooltipActive = true
  //     this.setTooltipData(event)
  //   })
  //   this.treemap.events.on('graph:mouseout', () => {
  //     this.tooltipActive = false
  //   })
  //   this.treemap.events.on('graph:mousemove', (event: MouseEvent) => {
  //     this.tooltipX = event.clientX
  //     this.tooltipY = event.clientY
  //   })
  //   this.treemap.events.on(
  //     'attributionFlow',
  //     async ({
  //       depth,
  //       leaf,
  //       attribution
  //     }: {
  //       depth: number
  //       leaf: HierarchyRectangularNode<any>
  //       attribution: string
  //     }) => {
  //       const data = leaf.data
  //       this.attributionFlowSourceData = { depth: 2, leaf, attribution }
  //       this.tooltipActive = false
  //       await this.getAttributionFlows(data)
  //     }
  //   )
  //   this.treemap.events.on('graphFlow', async (event: any) => {
  //     // avoid double click issues
  //     if (event.source && event.destination) {
  //       const { source, destination } = event
  //       await this.graphFlow(source, destination)
  //     }
  //   })
  // }

  // private tooltipType(): 'branch' | 'leaf' | 'attribution' {
  //   switch (this.depth) {
  //     case 0:
  //       return 'branch'
  //     case 1:
  //       return 'leaf'
  //     case 2:
  //       return 'attribution'
  //   }
  //   return 'branch'
  // }

  // private setTooltipData(item: HierarchyRectangularNode<MapChild>) {
  //   if (item != null) {
  //     const type = this.tooltipType()
  //     const data: TooltipInfoBranch | TooltipInfoLeaf =
  //       type === 'branch'
  //         ? {
  //             name: item.data.name,
  //             numLeaves: (<MapBranch>item.data).children.length,
  //             amountSum:
  //               (<MapBranch>item.data).valueLinear != null
  //                 ? (<MapBranch>item.data).valueLinear!
  //                 : this.accounting === 'LIFO' && this.depth === 0
  //                 ? Array.from(new Set((<MapLeaf[]>item.data.children).map(c => c.symbol))).join(', ')
  //                 : sum((<MapBranch>item.data).children.map((child: MapChild) => (<MapLeaf>child).amount)),
  //             symbol: (<MapLeaf[]>item.data.children)[0].symbol as string | undefined
  //           }
  //         : {
  //             name: item.data.name,
  //             amount: (<MapLeaf>item.data).amount as number,
  //             numPaths: (<MapLeaf>item.data).numPaths as number,
  //             shortestPath: (<MapLeaf>item.data).shortestPath as number,
  //             longestPath: (<MapLeaf>item.data).longestPath as number,
  //             symbol: (<MapLeaf>item.data).symbol as string | undefined
  //           }
  //     this.tooltipInfo = {
  //       name: item.data.name,
  //       type,
  //       data
  //     }
  //   }
  // }

  // private createGraph(container: HTMLElement, webGLContext: WebGLContext) {
  //   const { graph } = webGLContext
  //   container.appendChild(graph.view as HTMLCanvasElement)
  //   this.treemap.canvasMap({
  //     data: this.hierarchy as any,
  //     layout: this.layout,
  //     sqRatio: this.sqRatio,
  //     scale: this.scale,
  //     logScale: { min: this.logMin, max: this.logMax }
  //   })
  // }

  // updateDepth(depth: number, label: string) {
  //   if (depth > this.depth) {
  //     // push new breadcrumb
  //     this.breadcrumbs.push({ depth, label })
  //   } else if (depth < this.depth) {
  //     // pop breadcrumb
  //     this.breadcrumbs.pop()
  //   }
  //   this.depth = depth
  // }

  setDepth(depth: number) {
    this.failed = false
    const toPop = this.breadcrumbs.length - depth
    for (let i = 0; i < toPop; i++) {
      this.breadcrumbs.pop()
    }
    if (depth !== this.depth) {
      // restart paging if switching depths
      this.page = 1
      this.sorts = { amount: 'desc' }
    }
    this.depth = depth
    if (this.formatIndex === 0) {
      this.treemap.setDepth(depth)
    }
    if (this.depth === 2 && this.row != null) {
      this.getAttributionFlows()
    } else {
      this.updateAggregateHierarchy()
      // if (this.depth === 1) {
      //   const child = (<Category[]>this.hierarchy.children).find((category: Category) => category.name === row.name)
      //   if (child != null) {
      //     this.tableDatas[1] = child.children.map((i) => ({ id: md5(i), ...i }))
      //   }
      // }
    }
  }

  getEntity(target: Target): HierarchyEntity | undefined {
    const { type, id } = target
    if (type !== 'transaction') return { [type as 'attribution' || 'cluster' || 'address']: id }
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.setDepth(this.depth) // fetches new data
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.setDepth(this.depth) // fetches new data
  }

  formatDateRange(startTime: number, endTime: number) {
    // if (startTime === endTime) {
    //   return this.formatDate(startTime)
    // }
    // always return in range form
    return `${this.formatDate(startTime)} - ${this.formatDate(endTime)}`
  }

  formatDate(timestamp: number) {
    if (timestamp == null) { // some times are missing in mongo
      return '...'
    }
    const epoch = timeToMilliseconds(timestamp)
    return this.$store.state.formatDate(epoch, true, 1)
  }

  @Watch('target')
  private targetUpdated() {
    this.page = 1
    this.sorts = { amount: 'desc' }
    this.setDepth(0)
  }

  private async updateAggregateHierarchy() {
    this.show = false
    this.entity = undefined
    this.entityFilter = undefined
    this.failed = false
    this.$store.dispatch('clearAggregateHierarchy')
    if (this.target.type !== 'transaction') {
      const entity = this.getEntity(this.target)
      if (entity != null) {
        this.entity = entity
        if (isAddressEntity(entity)) {
          if (this.direction === 'source') {
            this.entityFilter = { destinationAddress: entity.address }
          } else {
            this.entityFilter = { sourceAddress: entity.address }
          }
        } else if (isClusterEntity(entity)) {
          if (this.direction === 'source') {
            this.entityFilter = { destinationCluster: entity.cluster }
          } else {
            this.entityFilter = { sourceCluster: entity.cluster }
          }
        } else {
          // attribution entity
          if (this.direction === 'source') {
            this.entityFilter = { destinationAttribution: entity.attribution }
          } else {
            this.entityFilter = { sourceAttribution: entity.attribution }
          }
        }
        this.show = true
        this.loading = true
        // get single layer, not hierarchy, in order to page
        const filters: Partial<AggregateFlowsRequestFilters> = { ...this.entityFilter }
        if (this.depth === 1) {
          const { label, symbol } = this.breadcrumbs[this.breadcrumbs.length - 1]
          filters[`${this.direction}Category`] = label
        }
        const results = await this.$store.dispatch('getAggregateFlows', {
          network: this.network,
          side: this.direction,
          trace: this.accounting,
          depth: this.depth,
          filters,
          page: this.page,
          perPage: this.perPage
        })
        if (!results && this.page > 1) this.page--
        this.loading = false
        // this.$store.dispatch('getAggregateHierarchy', {
        //   network: this.network,
        //   side: this.direction,
        //   trace: this.accounting,
        //   depth: 1,
        //   ...entity
        // })
      }
    } else {
      this.show = false
    }
  }

  // @Watch('visible')
  // private visibleUpdated() {
  //   if (!!this.visible && this.shouldUpdate) {
  //     this.graphInit()
  //   }
  // }

  // @Watch('hierarchy')
  // private hierarchyUpdated() {
  //   this.failed = false
  //   if (this.hierarchy != null) {
  //     this.tableDatas[0] = (<NonRecursiveHierarchicalAggregateFlows[]>this.hierarchy.children).map((i) => ({
  //       id: md5(i),
  //       ...i
  //     }))
  //     this.loading = false
  //     if (this.webGLContext != null) {
  //       this.destroy()
  //     }
  //     if (!!this.visible && this.formatIndex === 0) {
  //       this.graphInit()
  //     } else {
  //       this.shouldUpdate = true
  //     }
  //     if (this.depth !== 0) {
  //       this.setDepth(0)
  //     }
  //   }
  // }

  // @Watch('attributionFlowsKey')
  // private attributionFlowsUpdated() {
  //   this.loading = false
  //   const data = this.attributionFlows.get(this.attributionFlowsKey)
  //   if (data != null) {
  //     this.attributedFlows = data.children[0] as AttributedFlows
  //     if (this.formatIndex === 0) {
  //       // treemap
  //       if (this.attributionFlowSourceData != null) {
  //         const { depth, leaf, attribution } = this.attributionFlowSourceData
  //         leaf.data = data.children[0]
  //         this.treemap.nextDepth(depth, leaf, attribution)
  //       }
  //     } else {
  //       // table
  //       this.tableDatas[2] = this.attributedFlows.children.map((i: AttributedFlow) => ({ id: md5(i), ...i }))
  //     }
  //   }
  // }

  // @Watch('attributionFlowsFail')
  // private attributionFlowsFailUpdated() {
  //   this.loading = false
  //   this.failed = true
  // }

  destroy() {
    const container = this.$refs.treemap as HTMLElement
    if (container && container.hasChildNodes()) { // doesn't have children if there was no data before
      container.removeChild(this.webGLContext.graph.view as HTMLCanvasElement)
    }
    try {
      if (this.webGLGraph.graph) {
        this.webGLGraph.graph.destroy(true, true)
      }
    } catch (e) {}
  }
}
