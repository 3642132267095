import { Graphics } from "pixi.js"
import { Flow, Location, NoteNode } from '@/store/investigations/viz'
import { Viewport } from "pixi-viewport"
import { COLOR_LIGHT_GRAY, COLOR_POSTIT } from "./colors"
import PixiTextInputImpl from "pixi-text-input"
import { DropShadowFilter } from "pixi-filters"

export const GRID_SIZE = 20
export const NODE_PLACEMENT_DISTANCE = GRID_SIZE * 7
export const HALF_NODE_PLACEMENT_DISTANCE = NODE_PLACEMENT_DISTANCE / 2
export interface Grid {
  gfx: Graphics
  size: number
  worldScreenWidth: number
  worldScreenHeight: number
  worldWidth: number
  worldHeight: number
}

export const X_SCREEN_PADDING = 100

export const NOTE_PREFIX = '___' // indicates note node when begins a node id
export const NOTE_REF_PREFIX = '::' // indicates non-note node id being referenced by note node
export const NOTE_NODE_SEPARATOR = ',,,' // separates between different note node texts
export const NOTE_TYPES_SEPARATOR = '---' // separates between investigation notes and in-graph notes
export const NOTE_ID_TEXT_SEPARATOR = '|||' // separates between a note node id and the note text

export function getGreatestValueFromFlow(flows: Flow[] | undefined, targetKey: string) {
  if (flows == null) return 0
  for (const flow of flows) {
    const { id: flowId, index: flowIndex, isOutput: flowIsOutput } = flow.targetTransaction
    const flowKey = `${flowId}|${flowIndex}|${flowIsOutput}`
    if (flowKey === targetKey) {
      return flow.value
      break
    }
  }
  return 0
}

export function drawDashedPolygon(
  gfx: Graphics,
  points: Location[],
  x: number,
  y: number,
  rotation: number,
  dash: number,
  gap: number,
  offsetPercentage: number
) {
  let i
  let dashLeft = 0,
    gapLeft = 0
  if (offsetPercentage > 0) {
    const progressOffset = (dash + gap) * offsetPercentage
    if (progressOffset < dash) dashLeft = dash - progressOffset
    else gapLeft = gap - (progressOffset - dash)
  }
  var rotatedPoints = []
  for (i = 0; i < points.length; i++) {
    const p = {
      x: points[i].x,
      y: points[i].y
    }
    const cosAngle = Math.cos(rotation)
    const sinAngle = Math.sin(rotation)
    const dx = p.x
    const dy = p.y
    p.x = dx * cosAngle - dy * sinAngle
    p.y = dx * sinAngle + dy * cosAngle
    rotatedPoints.push(p)
  }
  for (i = 0; i < rotatedPoints.length; i++) {
    const p1 = rotatedPoints[i]
    const p2 = i === rotatedPoints.length - 1 ? rotatedPoints[0] : rotatedPoints[i + 1]
    const dx = p2.x - p1.x
    const dy = p2.y - p1.y
    const len = Math.sqrt(dx * dx + dy * dy)
    const normal = {
      x: dx / len,
      y: dy / len
    }
    let progressOnLine = 0
    gfx.moveTo(x + p1.x + gapLeft * normal.x, y + p1.y + gapLeft * normal.y)
    while (progressOnLine <= len) {
      progressOnLine += gapLeft
      if (dashLeft > 0) progressOnLine += dashLeft
      else progressOnLine += dash
      if (progressOnLine > len) {
        dashLeft = progressOnLine - len
        progressOnLine = len
      } else {
        dashLeft = 0
      }
      gfx.lineTo(x + p1.x + progressOnLine * normal.x, y + p1.y + progressOnLine * normal.y)
      progressOnLine += gap
      if (progressOnLine > len && dashLeft === 0) {
        gapLeft = progressOnLine - len
      } else {
        gapLeft = 0
        gfx.moveTo(x + p1.x + progressOnLine * normal.x, y + p1.y + progressOnLine * normal.y)
      }
    }
  }
}

export function drawGrid(viewport: Viewport, size: number): Grid {
  const gfx = new Graphics()
    .lineStyle(0.5, COLOR_LIGHT_GRAY, 0.5)
  gfx.zIndex = 0
  const { worldScreenWidth, worldScreenHeight, worldWidth, worldHeight } = viewport
  // apply grid to entire world to avoid it ending on zoom out
  const startWidth = worldScreenWidth - worldWidth
  const startHeight = worldScreenHeight - worldHeight
  for (let i = startWidth;i < worldWidth;i += size) {
    gfx.moveTo(i, startHeight)
      .lineTo(i, worldHeight)
  }
  for (let i = startHeight;i < worldHeight;i += size) {
    gfx.moveTo(startWidth, i)
      .lineTo(worldWidth, i)
  }

  viewport.addChild(gfx)
  return {
    gfx,
    size,
    worldScreenWidth,
    worldScreenHeight,
    worldWidth,
    worldHeight
  }
}

export function closestGridPoint(grid: Grid, point: Location): Location {
  const { worldScreenWidth, worldScreenHeight, worldWidth, worldHeight, size } = grid
  const { x: screenX, y: screenY } = point
  const relativeWidth = (worldScreenWidth - worldWidth)
  const relativeHeight = (worldScreenHeight - worldHeight)
  const worldX = screenX - relativeWidth
  const worldY = screenY - relativeHeight
  const snappedWorldX = Math.round(worldX / size) * size
  const snappedWorldY = Math.round(worldY / size) * size
  const x = snappedWorldX + relativeWidth
  const y = snappedWorldY + relativeHeight
  return { x, y }
}

export function getBoxBoundsFromCorners(corner1: Location, corner2: Location) :
  { lowerX: number; upperX: number; lowerY: number; upperY: number } 
{
  const { x: boxX1, y: boxY1 } = corner1
  const { x: boxX2, y: boxY2 } = corner2
  let lowerX, upperX, lowerY, upperY
  if (boxX1 > boxX2) {
    lowerX = boxX2
    upperX = boxX1
  } else {
    lowerX = boxX1
    upperX = boxX2
  }
  if (boxY1 > boxY2) {
    lowerY = boxY2
    upperY = boxY1
  } else {
    lowerY = boxY1
    upperY = boxY2
  }

  return { lowerX, upperX, lowerY, upperY }
}

export const NOTE_PADDING = 5
const DOUBLE_NOTE_PADDING = NOTE_PADDING * 2
const DEFAULT_NOTE_SIZE = 95
export const PIXI_TEXT_INPUT_CHANGE = 10 // for some reason gets added automatically to the width and height values

export function createNote(
  x: number,
  y: number,
  refId: string,
  noteId?: string,
  text: string = '',
  width: number = DEFAULT_NOTE_SIZE,
  height: number = DEFAULT_NOTE_SIZE
): NoteNode {
  const input = new PixiTextInputImpl({
    // this styling gets used in a mix of the DOM input and the PixiJS Text, which styling affects what is trial and error
    input: {
      multiline: true,
      fontSize: '12', // this mainly gets used to calculate lineHeight and gets `parseFloat`ed, so just a number
      fontFamily: 'arial', // to match the font family that gets used in the surrogate (pixi text)
      width: `${width}px`,
      height: `${height}px`
    },
    box: (w, h, state) => {
      const box = new Graphics()
      box.lineStyle({ width: 0 })
      box.beginFill(COLOR_POSTIT)
      box.drawRect(-NOTE_PADDING, -NOTE_PADDING, w + DOUBLE_NOTE_PADDING, h + DOUBLE_NOTE_PADDING) // increase rect dimensions to create padding
      box.endFill()
      box.closePath()

      // add a shadow
      const dropShadowFilter = new DropShadowFilter()
      box.filters = [dropShadowFilter as any]

      return box
    }
  })
  input.x = x
  input.y = y
  input.zIndex = 4
  input.text = text

  // overwrite DOM input styling so it's not using the same styling as the Pixi text
  input._dom_input.style['fontSize'] = '10px'

  return {
    id: noteId ?? `${NOTE_PREFIX}${Math.random()}${NOTE_REF_PREFIX}${refId}`,
    input,
    note: true,
    x,
    y,
    freeze: { x, y }
  }
}